<template>
    <!--    todo 暂不实现创建机构组时候批量添加机构-->
    <!--    todo 业务变更, 创建集团后,默认创建总部电商机构组-->
    <!--    todo 业务变更, 创建机构组后,在机构组中创建机构,不能单独创建机构 -->
    <div class="GroupCreate">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="组名称">
                            <el-input v-model="group.name" />
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="组类型">
                            <el-select v-model="group.deptType">
                                <el-option :value="3" label="门店" />
                                <el-option :value="2" label="配送中心" />
                                <el-option :value="1" label="电商总部" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="group.remark" />
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item>
                        <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                    </el-form-item>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';
import EfRemark from 'components/EfRemark';

export default {
    name: 'GroupEdit',
    components: { EfRemark },
    props: ['form'],
    data() {
        return {
            group: {
                deptType: 3,
                name: '',
                remark: '',
            },
            url: '/system/deptGroup/patch_c/',
        };
    },
    mounted() {
        this.group = {
            deptType: this.form.deptType,
            name: this.form.name,
            remark: this.form.remark,
        };
    },
    methods: {
        handleSave() {
            const group = this.group;
            if (!group.name) {
                this.$alert('请填写组名称', '提示', { type: 'warning' });
                return;
            }
            if (!group.deptType) {
                this.$alert('请选择组类型', '提示', { type: 'warning' });
                return;
            }
            if (!group.remark) {
                this.$alert('请填写备注', '提示', { type: 'warning' });
                return;
            }
            UrlUtils.PatchRemote(this, this.url + this.form.code, this.group, null, (res) => {
                this.goBackAndReload();
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
